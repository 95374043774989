
// Contact Page

.google-map {
  position: relative;


  #map {
    width: 100%;
    height: 450px;
    display: block;
    background: $gray-lighter;
    margin-bottom: 20px;
  }

  .btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.map-marker {
  display: inline-block;
  background: $brand-blue;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}



// Home

.jumbotron {
  margin: 0;
  background: no-repeat center center $gray;
  background-size: cover;
  padding-bottom: 6em;
  position: relative;



  * {
    position: relative;
    z-index: 2;
  }

  .pre-title {
    font-color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(-180deg, rgba(25,28,35,0.80) 0%, rgba(29,35,48,0.31) 97%);
    z-index: 1;
  }

  h1 {
    color: #fff;
    font-weight: bold;
    max-width: 90%;
    font-size: 3.25em;
    line-height: 1em;
    margin-bottom: .8em;
  }


  &.sub-page-header {

    padding-bottom: 50px;

    .btn-primary {
      position: absolute;
      bottom: 0;
      right: 0;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $font-family-alt;
      padding: 1.2em 2em;

      i:first-child {
        margin-right: .4em;
      }
    }

  }

  @media screen and (max-width: $screen-sm-max){
    text-align: center;

    h1{
      font-size: 3em;
      max-width: 100%;
    }
  }

  @media screen and (max-width: $screen-xs-max){

    h1{
      font-size: 2em;
      max-width: 100%;
    }
  }
}

.home-content {
  margin-bottom: 3em;

}

// Case Study List Page
.case-study-list {

  padding-top: 2em;
  padding-bottom: 2em;

  > .container {
    > .row {

      > [class^=col-]{
        border-right: 1px solid $gray-lightest;
        margin-bottom: 1.5em;

        @media screen and (max-width: $screen-sm-max){
          border: 0;
          border-bottom: 1px solid $gray-lightest!important;

        }

        &:last-child {
          border: 0;
        }

      }

    }
  }

}

// Case Study Page
.case-study-details {
  margin: 1em 0;
  background: $gray-lighter;
  padding: 1em 1.3em;
  border-radius: 6px;

  @media screen and (max-width: $screen-sm-max){
    text-align: center !important;
    font-weight: bold;

    .text-right {
      text-align: center !important;
      font-weight: 200;
    }
  }

}
// Toggle Trigger

$block-padding: 2.5rem;

.toggle-trigger {

  &:hover, &:focus {
    text-decoration: none;
  }

  .lead {
    color: $brand-primary;
    margin-bottom: 0.4em;

    // Arrow up
     &:before {
      content: "\f106";
      font-family: "FontAwesome", sans-serif;
       display: inline-block;
       margin-right: 10px;
       font-size: 0.8em;
    }
  }

  &.collapsed {
    .lead {

      // Arrow Down
       &:before {
        content: "\f107";
      }
    }
  }
}

// Intro Block
.intro-block {
  font-size: 1.38em;
  padding-bottom: $block-padding;
  padding-top: $block-padding;

  h2 {
    font-size: 1.27em;
    font-weight: bold;
    margin-bottom: 1em;
  }

  p {
    line-height: 1.5em;
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    color: $gray;
    max-width: 90%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 1.8em 15px;

    li {
      font-weight: 200;
      margin-bottom: 1em;
      position: relative;
      padding-left: 0.6em;
      font-size: 0.8em;
      line-height: 0.8em;

      &:before {
        font-family: 'FontAwesome';
        content: '\f10c';
        margin: 0 10px 0 -15px;
        color: $brand-primary;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0.8em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

//Image Block
.image-block {
  padding: $block-padding 0;

  .img-wrapper {
    .thumbnail {
      margin-bottom: 0;
    }
  }
}


// Two/Three Column Block

.column-block {

  display: flex;
  align-items: stretch;

  h2,h3,h4 {
    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    color: $brand-primary;
    font-weight: normal;
  }


  [class^=col-] {
    border-right: 1px solid $gray-lightest;
    padding-right: 2.5em;
    padding-left: 2.5em;

    img {
      max-width: 100%;
      height: auto;
    }

    &:first-child {
      padding-left: $padding-base-horizontal;
    }

    &:last-child {
      border: 0;
      padding-right: $padding-base-horizontal;
    }

    @media screen and (max-width: $screen-xs-max){
      padding-left: $padding-base-horizontal;
      padding-right: $padding-base-horizontal;
    }

    p:last-child {
      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0;
      }

    }

    p {
      img.full-width:last-child {
        margin-bottom: 0;
      }

    }



    p:last-child a:first-child {

      @extend .btn;
      @extend .btn-primary;

      background: $brand-primary;
      background-image: linear-gradient(90deg, $brand-primary-dark 0%, $brand-primary 100%);

    }
  }

  .col-sm-4 {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: $screen-xs-max){
      display: block;

    }
}

.three-column-block {

  //background-image: linear-gradient(-180deg, #F0F0F0 0%, #FFFFFF 100%);

  padding: $block-padding 0;

  .full-width {
    max-width: 100%;
    margin: 0 auto 1.5em auto;
  }

  [class^=col-sm] {

    border-right: 0;

    p {

    }
  }
}

.two-column-block {
  padding: $block-padding 0;

  .overall-title {
    margin-bottom: 2em;
  }
}


// Content Block
.content-block {

  padding-top: $block-padding;
  padding-bottom: $block-padding;


}


//Packages Block
.packages-block {
  background: $gray-light;
  padding-bottom: $block-padding;
  padding-top: $block-padding;



  .package-list {

    @media (min-width: $screen-sm) {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;

      .package {
        width: 33%;
      }
    }

    .package {
      background: #fff;
      margin-top: 1em;
      position: relative;
      padding-bottom: 60px;
      width: 100%;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      .package-header {
        color: #fff;
        background: no-repeat center center;
        background-size: cover;
        text-align: center;
        padding: 1.2em .2em;
        font-size: 2em;
        font-weight: normal;
        position: relative;
        display: block;

        * {
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
          position: relative;
          z-index: 2;
        }

        h3 {
          font-weight: 700;
          letter-spacing: -0.05em;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          /* Mask: */
          opacity: 0.8;
          background-image: linear-gradient(-180deg, rgba(43, 43, 43, 0.73) 0%, rgba(19, 19, 19, 0.34) 100%);
          z-index: 1;
        }
      }

      a.package-header {

        &:hover {
          text-decoration: none;

          &:before {
            background-color: rgba(0, 0, 0, .6);
          }
        }

      }

      .package-body {
        padding: 1.5em 1.2em;

      }

      .package-buttons {

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;

        a {

          color: #fff;
          text-align: center;
          width: 50%;
          background: $brand-primary;
          display: block;
          float: left;
          margin: 0;
          height: 60px;
          line-height: 60px;
          font-family: $font-family-alt;
          text-transform: uppercase;
          text-decoration: none !important;

          &:hover {
            background: darken($brand-primary, 10%);
          }

          &.full {
            width: 100%;
          }

          &.learn-more {

          }
          &.order-now {
            font-weight: bold;
            /* Rectangle 30: */
            background-image: linear-gradient(-180deg, lighten($brand-primary, 10%) 0%, darken($brand-primary, 10%) 100%);

            &:hover {
              background: darken($brand-primary, 10%);
            }

          }

        }

      }
    }
  }
}

// Callout Block
.callout-block {
  padding: $block-padding 0;

  .well:last-child {
    margin-bottom: 0;
  }
}

// Pricing Options
.pricing-options-block {

  padding: $block-padding 0;

  .pricing-option {
    margin-bottom: 1em;
    background: $brand-blue;
    position: relative;

    .row {
      margin: 0;
      display: table;
      width: 100%;
    }

    [class^=col-] {
      text-align: center;
      vertical-align: middle;
      color: #fff;
      float: none;
      display: table-cell;
      position: relative;
    }

    .title {
      margin: 0;
      width: 100%;
      font-size: 1.5em;
      line-height: 1em;
      padding: 15px 30px;
      font-weight: bold;

      .price-xs {
        font-size: 2em;
        margin: .5em 0;
      }

      .learn-more-xs {
        text-transform: uppercase;
        font-size: 0.6em;
        font-weight: 300;
        display: block;
        margin-top: 0.5em;
      }

      @media (min-width: $screen-sm) {
        font-size: 1.2em;
        width: 30%;
      }

      @media (min-width: $screen-md) {
        font-size: 1.5em;
      }

      @media (min-width: $screen-lg) {
        font-size: 1.6em;
      }
    }

    .description {
      text-align: left;
      padding: 15px 30px;
      width: 35%;
      display: none;

      p {
        color: inherit;
        font-size: 1.3em;
        line-height: 1.2em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (min-width: $screen-sm) {
        display: table-cell;

        p {
          font-size: 1em;
        }
      }

      @media (min-width: $screen-lg) {
        p {
          font-size: 1.2em;
        }
      }
    }

    .pricing {
      padding: 15px 30px;
      width: 17.5%;
      background-image: linear-gradient(-90deg, #26275A 0%, #111978 100%);
      display: none;

      @media (min-width: $screen-sm) {
        display: table-cell;
      }

      .cost {
        font-size: 3em;
        line-height: 1em;
        font-weight: bold;
        display: block;
        width: 100%;
      }

      .rate {
        display: block;
        width: 100%;
        font-size: 0.8em;
        padding: 0;
        font-weight: 300;
        font-family: $font-family-alt;
        text-transform: uppercase;
      }
    }

    .learn-more {
      text-transform: uppercase;
      font-weight: 300;
      font-family: $font-family-alt;
      transition: background 0.5s;
      width: 17.5%;
      display: none;

      &:hover {
        background: $brand-primary;
        text-decoration: none;
      }

      @media (min-width: $screen-sm) {
        display: table-cell;
      }
    }

    &.green {
      background-color: $brand-primary;

      .pricing {
        background-image: linear-gradient(-180deg, $brand-primary-dark 0%, $brand-primary 100%);
      }

      .learn-more {
        &:hover {
          background: darken($brand-secondary, 35%);
        }
      }
    }

    &.red {
      background-color: #B6182C;

      .pricing {
        background-image: linear-gradient(-180deg, lighten(#B6182C, 20%) 0%, #B6182C 100%);
      }

      .learn-more {
        &:hover {
          background: darken(#B6182C, 10%);
        }
      }
    }

    &.grey {
      background-color: $gray-lighter;

      .title {
        color: $brand-blue;
      }

      .description {
        color: black;
      }

      .pricing {
        background-image: linear-gradient(-180deg, lighten(#fff, 20%) 0%, $gray-lighter 100%);

        .cost {
          color: $brand-blue;
        }

        .rate {
          color: $brand-blue;
        }
      }

      .learn-more {
        color: $brand-blue;

        &:hover {
          background: darken($gray-lighter, 10%);
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

}

// Callout
.callout-title {
  font-size: .813em;
  font-family: $font-family-alt;
  text-transform: uppercase;
  margin: 2em 0 1em 2.5em;

  i {
    margin-right: .4em;
  }
}

// Bios
.bio-block {
  border-bottom: 1px solid $gray-lighter;
  padding: $block-padding 0;

  h2 {
    margin-top: 0;
  }


}

.featured-listing {
  display: block;
  color: inherit;
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid $gray-lighter;

  img {
    border: 1px solid $gray-lighter;

    @media (max-width: $screen-xs) {
      margin-bottom: 2em;
    }
  }

  &:last-child {
    border: none;
  }

  @media (min-width: $screen-sm) {
    .row {
      display: flex;
      align-items: center;
    }
  }

  .featured-title {
    font-size: 1.2em;
    text-align: center;
    margin: 0 0 0.2em 0;

  }

  &.case-study {
    .featured-title {
      font-size: 1.2em;
    }
  }

  img {
    width: 145px;
    max-width: 100%;
    height: auto;
  }

  .featured-subtitle {
    text-align: center;
    font-size: 1.2em;
    padding: 0 1em;
    font-weight: 300;
    margin-bottom: 1em;

    @media (max-width: $screen-xs) {
      margin-bottom: 2em;
    }
  }

  .profile-meta {
    font-size: 1.2em;

    a {
      display: inline-block;
      margin: 0 0.1em;
    }
  }

  &.case-study .profile-meta {

    a {
      font-size: .6em;
      text-transform: uppercase;
      font-weight: 300;
      border-radius: 50px;
      border: 1px solid $brand-blue;
      color: $brand-blue;
      background: transparent;
      margin-top: 1em;
    }
  }

  .description {
    p {
      font-weight: 300;
      margin-bottom: 1.8em;
      font-size: 1.2em;
      line-height: 1.7em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .well {
    margin-top: 2em;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .toggle-trigger {
    font-size: 1.2em;
    font-weight: 300;
    margin-top: 0.8em;
    display: inline-block;

    &:hover, &:focus {
      text-decoration: none;
    }

    &:before {
      content: "\f090";
      font-family: "Black Tie", sans-serif;
      display: inline-block;
      margin-right: 10px;
      font-size: 0.8em;
    }

    &.collapsed {
      &:before {
        content: "\f091";
      }
    }
  }

  .label {
    margin-right: 1em;
  }
  .btn {
    font-size: .778em;
  }


  &:hover, &:active, &:focus {
    color: inherit;
  }


  @media screen and (max-width: $screen-xs-max){
    text-align: center;
  }
}

// HTML Block

.raw-html-block {
  padding: $block-padding 0;
}

// Features block

.features-block {
  padding: $block-padding 0;

  .link-block-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}


//Key Features Block

.key-features-block {

  padding: $block-padding 0;

  .overall-intro {

    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 2em;
    }

  }


  .key-feature {

    padding-bottom: .8em;
    padding-right: 2em;

    border-right: 1px solid $gray-lightest;
    margin-bottom: 1.5em;

    &:last-child {
      border: 0;
    }

    h3 {
      margin-bottom: .2em;
      margin-top: 0;
    }

    .key-feature-intro {

      p:last-child {
        margin-bottom: .2em;
      }
    }


  }

}

//Toggle Block
.toggle-block {

  padding: $block-padding*0.4 0;

  & + .toggle-block {
    padding-top: 0;
  }
  .content-block + & {
    padding-top: 0;
  }

  .well:last-child {
    margin-bottom: -0;
  }
}

// Table Block
.table-block {
  padding: $block-padding 0;

  .table-responsive:last-child table {
    margin-bottom: 0;
  }
}

// GALLERY

.gallery-block {
  padding: $block-padding 0;
}

.gallery-list {
  .gallery-image {
    background: white;
    display: block;
    height: 300px;
    width: 100%;
    margin-bottom: 30px;
    background-size: contain!important;
    background-position: center!important;
    background-color: $gray-lighter !important;
    background-repeat: no-repeat !important;
    position: relative;
    opacity: 1;

    @media (min-width: $screen-sm) {
      height: 200px;
    }

    @media (min-width: $screen-md) {
      height: 300px;
    }

    .overlay {
      height: 100%;
      width: 100%;
      line-height: 300px;
      font-size: 3em;
      text-align: center;
      display: none;
      background: rgba(255,255,255,0.8);
      z-index: 2;
    }
    
    .caption {
      background: rgba(255,255,255,.8);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: .5em 0;
      z-index: 1;
      opacity: 1;

      p {
        margin: 0;
        padding: 0;
        color: $gray;
      }

    }
    
    &:hover {
      .overlay {
        display: block;

        i {
          opacity: .5;
        }
      }
      .caption {
        display: none;
      }
    }

    
  }

  .row {
    &:last-child {
      .gallery-image {
        margin-bottom: 0;
      }
    }
  }
}

/* Lightbox */
body:after {
  content: url('../../img/lightbox/close.png') url('../../img/lightbox/loading.gif') url('../../img/lightbox/prev.png') url('../../img/lightbox/next.png');
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url('../../img/lightbox/loading.gif') no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url('../../img/lightbox/prev.png') left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url('../../img/lightbox/next.png') right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url('../../img/lightbox/close.png') top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.big-list-block {

  padding: $block-padding 0;


  .overall-title {
    margin-bottom: 4em;
  }

  ol {
    @extend .list-unstyled;

    counter-reset: li;
    margin: 2em 0;

    > li {
      padding-left: 7em;
      position: relative;
      margin: 1.5em 0;
      min-height: 4em;

      &:last-child{
        margin-bottom: 0;
      }

      &:before {
        font-size: 2em;
        content: counter(li);
        counter-increment: li;
        position: absolute;
        top: -.2em;
        left: 0;
        width: 2.5em;
        height: 2.5em;
        color: $brand-primary;
        border: 4px solid $brand-primary;
        border-radius: 100%;
        line-height: 2.28em;
        text-align: center;
        padding: 0;
        display: block;
        padding-left: .1em;
      }

    }

  }

}
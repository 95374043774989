.promoted-links {

  .pre-title {
    color: #aaa;
  }

  .row {
    display: flex;
    align-items: stretch;

    @media screen and (max-width: $screen-xs-max){
      display: block;
    }


  }

  [class^=col] {
    padding: 1.8em 1.5em;

    border-left: 1px solid $gray-light;

    &:first-child {
      border-left: 0;
    }

    @media screen and (max-width: $screen-xs-max){
      border-left: 0;
      border-bottom: 1px solid $gray-light;
    }
  }

  h4 {
    font-size: 1.5em;
  }




}


.knowledge-base-container {
  background: $brand-primary;
  color :#fff;
  line-height: 1.8em;
  padding-top: 2em;
  padding-bottom: 2.2em;

  .overall-title {
    color: inherit;
    border-color: rgba(255,255,255,.4);
  }

  ul {
    @extend .list-unstyled;
    margin-bottom: 0;

    li {

      a {
        color: inherit;
        opacity: .6;

        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }

    }

  }

  .support-link {
    text-align: right;
    color: rgba(255,255,255,.6);
    font-family: $font-family-alt;
    text-transform: uppercase;
    font-size: .813em;
    margin-top: 2em;

    border-top: 1px solid rgba(255,255,255,.4);

    padding-top: 1em;

    p {
      color: inherit;
      font-family: inherit;
    }

    a {
      color: #fff;
      font-weight: bold;
    }

    @media screen and (max-width: $screen-sm-max){
      text-align: center;
    }

  }

  @media screen and (max-width: $screen-sm-max){
    text-align: center;
  }

}


footer {
  background: $brand-primary-dark;
  color: #fff;
  padding: 3.5em 0;
  font-size: .875em;
  color: $brand-primary-light;

  hr {
    border-color: $brand-primary-light;
    opacity: .4;
  }

  .footer-header {
    .img-responsive {
      position: relative;
      top: -.7em;
    }
  }

  .row {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    margin-bottom: 1em;
  }

  a {
    color: $brand-primary-light;

    &:hover {
      color: #fff;
    }
  }


  .social-icons {
    a {
      font-size: 2em;
    }
  }

  h4 {
    @extend .pre-title;
    padding: 0;
    margin-bottom: 2em;
    color: #fff;

    &:after {
      content: none;
    }
  }

  ul {
    li {
      margin-bottom: .5em;
    }
  }

  @media screen and (max-width: $screen-xs-max){
    text-align: center;

    h4 {
      font-size: 1em;
      margin-top: 1.5em;
      margin-bottom: 1em;
    }
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max){
    img {
      margin-left: auto;
      margin-right: auto;
    }

    .footer-header {
      text-align: center;
      padding-bottom: 1.5em;
    }
  }

  .footer-link-list {

    li {

    }

    a {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $font-family-alt;
      font-size: .813em;
      margin-left: 5em;

      &:hover {
        color: $brand-primary-light;
      }
    }
  }
}